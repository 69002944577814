<template>
  <v-layout column="" pr-3 mr-2 mt-1>

    <v-dialog v-model="dialog" width="500">
      <v-card>

      </v-card>
    </v-dialog>

    <v-row>

      <v-data-table :headers="headers" :items="rating"  sort-by="calories"
        class="elevation-1 request_table" style="width:96%" hide-default-footer="" :loading="loading"
        loading-text="جاري تحميل البيانات">

        

        <template v-slot:top>
          <v-toolbar flat color="white" pb-5>

            <v-toolbar-title>تقيم</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-row justify="end" style="margin-top:20px;">







            </v-row>

          </v-toolbar>
        </template>



        <!-- 
        <template v-slot:bottom>
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
        </template> -->
        <!-- edit reservation -->


        <!-- edit reservation -->
        <template v-slot:item.rate_value="{ item }">

          <v-rating color="#f29c2b" background-color="gray" readonly="" size="25" v-model="item.rate_value">
          </v-rating>

        </template>
        <template v-slot:no-data>
          <h4>لاتوجد بيانات</h4>
        </template>



      </v-data-table>

    </v-row>
    <v-row>
      <v-pagination v-model="page" prev-icon="mdi-menu-right" next-icon="mdi-menu-left" circle="" :length="pageCount">
      </v-pagination>

    </v-row>
  </v-layout>

</template>


<script>
  //const axios = require('axios');
  export default {
    data: () => ({


      user_info: {},
      res_filter: '',
      dialog: false,
      rating: [],
      loding_accept: false,
      marvelHeroes: [],
      loding_cancel: false,
      headers: [{
          text: '#',
          align: 'center',
          sortable: false,
          value: 'id',
        },
        {
          text: 'اسم الخدمه',
          align: 'center',
          sortable: false,
          value: 'item.item_name',
        },
        {
          text: 'اسم الزبون',
          align: 'center',
          sortable: false,
          value: 'user.full_name',
        },


        {
          text: 'الملاحظات',
          align: 'center',
          sortable: false,
          value: 'comment',
        },



        {
          text: 'تاريخ الاضافة',
          value: 'created_at',
          align: 'center',
          sortable: false
        },

        {
          text: ' ',
          value: 'status',
          align: 'center',
        },
        {
          text: ' ',
          value: 'rate_value',
          align: 'center',
        },
      ],

      editedIndex: -1,
      editedItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },

      defaultItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },


      valid: false,
      page: 1,
      pageCount: 0,
      current_page: 1,
      last_page: 0,
      loading: false


    }),



    methods: {




      getitems() {
     
        var url = "/ratings/owner/get?page=" + this.current_page;
        this.loading = true;
        this.$http({
          method: 'get',
          url: url,
          headers: {

          }

        }).then(response => {
          this.rating = response.data.data;


          this.last_page = response.data.meta.last_page;
          this.pageCount = response.data.meta.last_page;


        }).catch(error => {
          error
          // this.$swal('خطاء', "خطاء بالاتصال", 'error')
        }).finally(s => {
          s,
          this.loading = false;

        });
      },


    getMoreitems() {

        if (this.current_page <= this.last_page) {
         
          this.current_page = this.page;
          this.getitems();
        }



      },





    },

    created() {
      this.getitems();

    },
    mounted() {





    },
     computed: {

      selected: function () {
        return this.getMoreitems();
      }
    },


    watch: {
      selected: 'search by sub_cat_id',
    },
  }
</script>